import { VariantProps } from "class-variance-authority";
import { buttonVariants } from "./button";
import { chipVariants } from "./chip";

export type Colors =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'error'
  | 'info'
  | 'warning';

export type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export type ButtonVariant =
  | 'default'
  | 'contained'
  | 'outlined'
  | 'secondary'
  | 'ghost'
  | 'link'
  | 'andy'
  | 'ksl'
  | 'dealertools'
  | 'dealertoolslink';

export type ChipVariant = 'contained' | 'outlined';

export type ButtonSize = Size | 'icon' | 'smIcon';

export const spinnerSizes: Record<ButtonSize, Size> = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl',
  xxl: 'xxl',
  icon: 'md',
  smIcon: 'md',
};

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  asChild?: boolean;
  loading?: boolean;
  lowercase?: boolean;
  color?: Colors;
  spinnerColor?: Colors;
  spinnerSize?: Size;
  adornment?: React.ReactNode;
}
export interface ChipProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof chipVariants> {
  label: string;
  onDelete?: (event: React.MouseEvent | React.TouchEvent) => void;
  deleteIcon?: boolean;
  asChild?: boolean;
  lowercase?: boolean;
}
