'use client';

import * as React from 'react';
import { Slot } from '@radix-ui/react-slot';
import { cva } from 'class-variance-authority';
import { cn } from 'src/utils/@/lib/utils';
import { useRipple } from './effects/ripple';
import { LoadingSpinner } from './effects/loading';
import {
  ButtonProps,
  ButtonSize,
  Colors,
  ButtonVariant,
  spinnerSizes,
} from './types';

const buttonVariants = cva(
  'inline-flex items-center justify-center gap-2 whitespace-nowrap rounded-md transition-all duration-300 ease-in-out shadow-[0_4px_4px_rgba(0,0,0,0.1)] text-sm font-medium focus-visible:outline-none focus-visible:ring-1 focus-visible:ring-ring disabled:pointer-events-none disabled:opacity-50 [&_svg]:pointer-events-none [&_svg]:size-4 [&_svg]:shrink-0 uppercase',
  {
    variants: {
      variant: {
        default: 'bg-primary hover:bg-primary/60',
        contained:
          'bg-primary text-primary-foreground hover:bg-primary-hover hover:shadow-[0_5px_12px_rgba(0,0,0,0.2)]',
        outlined:
          'border border-gray-300 text-gray-400 border-input bg-transparent shadow-sm hover:border-gray-400 hover:bg-gray-500/5',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary/80',
        ghost:
          'shadow-none bg-transparent hover:bg-accent hover:text-accent-foreground',
        link: 'text-primary bg-transparent shadow-none underline-offset-4 hover:underline',
        andy: 'transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300',
        ksl: 'transition ease-in-out delay-150 bg-blue-500 hover:-translate-y-1 hover:scale-110 hover:bg-indigo-500 duration-300',
        dealertools:
          'transition ease-in-out delay-150 bg-primary hover:-translate-y-1 hover:scale-110 hover:bg-primary-foreground duration-300',
        dealertoolslink:
          'text-link underline-offset-4 hover:underline px-1 my-0 mx-0 shadow-none text-md',
      },
      size: {
        xs: 'h-[21px] px-3.5 text-[10px]',
        sm: 'h-[30px] px-3.5 text-[13px]',
        md: 'h-[39px] px-[23px] py-2',
        lg: 'h-[43px]  px-[27px] text-[15px]',
        xl: 'h-[56px]  px-[40px] text-[20px]',
        xxl: 'h-[64px]  px-[48px] text-[36px]',
        icon: 'h-fit w-fit p-2.5',
        smIcon: 'h-fit w-fit p-0.5',
      },
      color: {
        primary: 'bg-primary text-primary-foreground hover:bg-primary-hover',
        secondary:
          'bg-secondary text-secondary-foreground hover:bg-secondary-hover',
        success: 'bg-success text-success-foreground hover:bg-success-hover',
        error: 'bg-error text-error-foreground hover:bg-error-hover',
        info: 'bg-info text-info-foreground hover:bg-info-hover',
        warning: 'bg-warning text-warning-foreground hover:bg-warning-hover',
      },
      lowercase: {
        true: 'normal-case',
        false: 'uppercase',
      },
    },
    compoundVariants: [
      ...(
        [
          'primary',
          'secondary',
          'success',
          'error',
          'info',
          'warning',
        ] as Colors[]
      ).flatMap((color) => [
        {
          variant: 'outlined' as ButtonVariant,
          color,
          className: `border-${color} text-${color} bg-transparent hover:bg-gray-500/5 hover:border-${color}-hover`,
        },
        {
          variant: 'link' as ButtonVariant,
          color,
          className: `text-${color} bg-transparent hover:bg-transparent hover:text-${color}-hover`,
        },
        {
          variant: 'ghost' as ButtonVariant,
          color,
          className: `text-${color} bg-transparent hover:bg-gray-500/10 hover:text-${color}-hover`,
        },
      ]),
    ],
    defaultVariants: {
      variant: 'default',
      size: 'md',
      lowercase: false,
    },
  }
);

const Button = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & { fullWidth?: boolean }
>(
  (
    {
      className,
      variant,
      size,
      color,
      loading = false,
      lowercase = false,
      asChild = false,
      fullWidth = false,
      children,
      spinnerColor,
      spinnerSize,
      adornment,
      ...props
    },
    ref
  ) => {
    const Comp = asChild ? Slot : 'button';
    const { rippleHandlers, rippleClassName } = useRipple({
      variant: variant as ButtonVariant,
    });

    return (
      <Comp
        className={cn(
          buttonVariants({ variant, size, color, lowercase, className }),
          rippleClassName,
          fullWidth && 'w-full',
          className
        )}
        ref={ref}
        disabled={loading || props.disabled}
        {...rippleHandlers}
        {...props}
      >
        <div className="flex flex-row gap-2 items-center">
          {children}
          {adornment}
          {loading && (
            <LoadingSpinner
              size={
                spinnerSize ?? (size ? spinnerSizes[size as ButtonSize] : 'sm')
              }
              color={spinnerColor}
            />
          )}
        </div>
      </Comp>
    );
  }
);

Button.displayName = 'Button';

export { Button, buttonVariants };
